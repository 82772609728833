.print_source {
  display: none;
  width: 380px;
  padding: 15px;
  margin: 0 auto;
}

.centered {
  text-align: center;
}

.margin_right {
  margin-right: 15px;
}

@media print {
  .print_source {
    display: block !important;
  }
}

.size_1{
  font-size: 10px;
}

.fees_table th{
  font-size: 12px;
}


.fees_table td{
  font-size: 12px;
}