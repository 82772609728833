@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');


body {
  margin: 0;
  background-color: #fafafa !important;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
table thead tr th{
  background-color: rgb(239, 239, 239) !important;
}
.css-19kzrtu {
  padding: 20px 0px 0px !important;
}

